import React, { useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react';

const Iframe = ({ data: { url, height, width }, ...props }) => {
  const resizable = true;
  const [loadCount, setLoadCount] = React.useState(0);

  const incrementLoadCount = () => {
    setLoadCount(prev => prev + 1);
  };

  // Redirect to home page if iframe is reloaded beyond the first time and the URL is from keela.co
  useEffect(() => {
    if (loadCount > 1 && url.includes('keela.co')) {
      window.location.href = '/';
    }
  }, [loadCount]);

  return resizable ? (
    <IframeResizer
      log
      id="keela_3zKcdYNiGedGJ4w67"
      src={url}
      frameBorder="0"
      heightCalculationMethod="max"
      scrolling={true}
      style={{
        width: '1px',
        minWidth: '100%',
        minHeight: `${height || '500'}px`,
      }}
      onLoad={incrementLoadCount}
    />
  ) : (
    <iframe
      title="Careers"
      id="keela_3zKcdYNiGedGJ4w67"
      width={width || '100%'}
      height={height || '500'}
      src={url}
      onLoad={incrementLoadCount}
    />
  );
};

export default Iframe;
